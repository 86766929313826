<template>
  <section id="my-hubs">
    <b-card>
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @row-clicked="rowClicked"
      >

      <template #cell(process_configurations)="data">
        <b-badge v-for="item in data.value" :key="item.id" variant="light-primary">
          {{ item.process_name }}
        </b-badge>
      </template>

      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @change="handlePageChange"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
  BPagination,
  BCardBody,
} from "bootstrap-vue"
import axios from "axios"

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BPagination,
    BCardBody,
  },
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      sortBy: "id",
      sortDesc: true,
      sortDirection: "desc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "id", label: "id", sortable: true },
        { key: "name", label: "name", sortable: true },
        { key: "ofn_id", label: "ofn id", sortable: true },
        { key: "process_configurations", label: "processes", sortable: true },
        {
          key: "orders_close_at",
          label: "Orders close at",
          sortable: true,
          formatter: value => {
            let new_date = new Date(value)
            if (value) {
              return new_date.toLocaleString("de-DE", {
                timeZone: "Europe/Berlin"
              })
            }
            else {
              return ""
            }
          }
        },
      ],
      /* eslint-disable global-require */
      items: [],
      queryParams: {},
    }
  },
  methods: {
    rowClicked(row) {
      this.$router.push({ name: "hub-details", params: { id: row["id"] } })
    },
    getMyHubs() {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/hubs/", {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          },
          params: this.queryParams,
        })
        .then(response => {
          this.items = response.data.results
          this.totalRows = response.data.count * 2
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
    handlePageChange(value) {
      this.queryParams["limit"] = 10
      this.queryParams["offset"] = (value-1) * 10
      this.getMyHubs()
    }
  },
  created() {
    this.getMyHubs()
  }
}
</script>

<style>

</style>
